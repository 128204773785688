import React from 'react';
import dayjs from 'dayjs';

import 'dayjs/locale/fr';
import relativeTime from 'dayjs/plugin/relativeTime';

import Photo from '../../models/Photo';

import { getPhotoUrl } from '../../hooks/useStoryPhotos';

import './StoryPhoto.css';

dayjs.locale('fr');
dayjs.extend(relativeTime);

export interface StoryPhotoProps {
    photo: Photo;
}

export default function StoryPhoto({ photo }: StoryPhotoProps) {
    return (
        <div className="story-photo">
            <img src={getPhotoUrl(photo.filename, { w: 1000 })} alt="" className="story-photo_photo"/>
            <div className="story-photo_header">
                <div className="story-photo_header_title">{photo.created_by}</div>
                <div className="story-photo_header_subtitle">{dayjs(photo.created_at.seconds * 1000).fromNow()}</div>
            </div>
        </div>
    );
}
