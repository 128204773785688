import { Fade } from 'react-slideshow-image';

import 'react-slideshow-image/dist/styles.css';

import './StoryPhotosContainer.css';

import useStoryPhotos from '../../hooks/useStoryPhotos';

import Spinner from '../../components/Spinner';
import { StoryPhoto } from '../../components/StoryPhoto';

export interface StoryPhotosContainerPtops {
    id: string;
}

function StoryPhotosContainer({ id }: StoryPhotosContainerPtops) {
    const [ items, loading ] = useStoryPhotos({ id });

    if (loading) {
        return (
            <div className="story-photos story-photos--loading">
                <Spinner/>
            </div>
        );
    }

    if (!items || items.length === 0) {
        return (
            <div className="story-photos story-photos--empty">
                <div className="story-photos_empty">Aucune Photo 😕</div>
            </div>
        );
    }

    return (
        <div className="story-photos">
            <Fade cssClass="story-photos_slideshow" transitionDuration={0} arrows={false} autoplay={true} infinite={true} pauseOnHover={false} canSwipe={false}>
                {items.map((photo, index) => (
                    <StoryPhoto key={`photo_${index}`} photo={photo}/>
                ))}
            </Fade>
        </div>
    );
}

export default StoryPhotosContainer;
