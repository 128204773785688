import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import './index.css';

import '@fontsource/poppins/200.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';

import App from './App';
import ColdRefresh from './components/ColdRefresh';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
      <React.StrictMode>
          <BrowserRouter>
              <App />
              <ColdRefresh/>
          </BrowserRouter>
      </React.StrictMode>
  </React.StrictMode>
);
