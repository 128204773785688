import React from 'react';
import { useParams } from 'react-router-dom';
import { StoryPhotosContainer } from '../containers/StoryPhotosContainer';

export default function StorySlideshowView() {
    const { id } = useParams();

    if (!id) {
        return <div>Loading...</div>;
    }

    return (
        <StoryPhotosContainer id={id}/>
    );
}
