import { useEffect } from 'react';

const TIMEOUT = 60 * 60 * 1000;

function ColdRefresh() {
    useEffect(() => {
        const timeout = setTimeout(() => {
            window.location.reload();
        }, TIMEOUT);

        return () => clearTimeout(timeout);
    }, []);

    return null;
}

export default ColdRefresh;
