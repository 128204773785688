import React from 'react';
import { Hearts } from 'react-loader-spinner';

import './Spinner.css';

export type SpinnerProps = {
    color?: string;
};

export default function Spinner({ color }: SpinnerProps) {
    return (
        <div className="spinner-container">
            <Hearts
                color={color ?? 'white'}
                height={60}
                width={60}
            />
        </div>
    );
}
