import { useCollectionData } from 'react-firebase-hooks/firestore';

import firebase from '../utils/firebase';

import Photo from '../models/Photo';

export type useStoryPhotosProps = {
    id: string;
};

export type useStoryPhotosResponse = [ Photo[] | undefined, boolean, firebase.FirebaseError | undefined ];

function useStoryPhotos({ id }: useStoryPhotosProps): useStoryPhotosResponse {
    const [ items, loading, error ] = useCollectionData<Photo>(
        firebase.firestore().collection(`/stories/${id}/photos`).orderBy('created_at', 'asc')
    );

    return [
        items,
        loading,
        error,
    ];
}

export const getPhotoUrl = (filename: string, options?: { w?: number; h?: number; }) => {
    const queryParams = {
        ...options,
        signature: process.env.REACT_APP_SIGNATURE,
    }

    const queryString = (Object.keys(queryParams) as (keyof typeof queryParams)[]).map(param => `${param}=${queryParams[param]}`).join('&');

    return `${process.env.REACT_APP_BASE_URL}/images/${filename}?${queryString}`;
}

export default useStoryPhotos;
