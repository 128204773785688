import React from 'react';
import { Route, Routes } from 'react-router-dom';

import StorySlideshowView from './views/StorySlideshowView';

function App() {
  return (
      <Routes>
        <Route path="/s/:id" element={<StorySlideshowView />} />
      </Routes>
  );
}

export default App;
